.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-container h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 63px;
  margin-bottom: 0;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.pricing-container p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 163.15%;
  margin-bottom: 20px;
  /* identical to box height, or 33px */

  text-align: center;

  /* Grey */

  color: #6d7d8b;
}

.pricing-card {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  background-color: #1e2029;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem 1rem 2rem;
  /* padding: 2rem; */
  border-radius: 20px;
}

.pricing-card h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.pricing-card span {
  margin-bottom: 5px;
}

.pricing {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 1.5rem;
}

.discount {
  background-color: #ff664f;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.discount span {
  font-size: 1.5rem;
  font-weight: bold;
}

.discount p {
  font-size: 0.8rem;
  color: #ffffff;
}

.price {
  text-align: center;
}

.price p {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.price span {
  font-size: 1rem;
}

.features {
  list-style: none;
  margin-bottom: 2rem;
}

.features li {
  margin-bottom: 0.5rem;
}

.btn-select-plan {
  background-color: #12141d;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  margin-top: 10px;
  cursor: pointer;
}

.btn-select-plan:hover {
  background-color: #ff664f;
}

@media (max-width: 768px) {
  .pricing-card {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .pricing-card {
    width: 70%;
    padding-bottom: 2rem;
  }

  .pricing-container h2 {
    font-size: 30px;
  }

  .pricing-container p {
    font-size: 16px;
  }

  .pricing-card h3 {
    font-size: 1.5rem;
  }

  .price p {
    font-size: 2.5rem;
  }

  .btn-select-plan {
    width: 180px;
    margin-top: 2rem;
  }
}
