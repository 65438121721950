nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #12141d;
  color: #fff;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  border-radius: 10px;
}

.nav-left {
  display: flex;
  align-items: center;
}

.logo {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  color: #fff;
}

.menu-icon {
  display: none;
}

nav li {
  margin: 0 10px;
}
nav a {
  text-decoration: none;
}

.nav-elements ul a:hover,
.nav-elements ul a:active {
  color: #fff;
}

.mobile {
  display: none;
}

.nav-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

button {
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
}

.btnFirst {
  background-color: #22222a;
  color: #fff;
}

.btnLast {
  background-color: #fff;
  color: #12141d;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #6d7d8b;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .nav-left {
    width: 100%;
    justify-content: space-between;
  }
  .logo {
    font-size: 25px;
  }
  .menu-icon {
    display: grid;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #272932;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .mobile {
    display: block;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .btnFirst,
  .btnLast {
    background-color: #272932;
    margin-left: 30px;
    margin-top: -5px;
    color: #6d7d8b;
    font-size: 16px;
    font-weight: 400;
  }

  .btnFirst {
    margin-top: -10px;
  }

  .nav-right {
    display: none;
  }
}
