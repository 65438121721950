.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.hero-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}

.hero-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 65px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.hero-subtitle {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 163.15%;
  text-align: center;
  color: #6d7d8b;
  width: 80%;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.sign-in-button {
  background: #22222a;
  color: white;
  border: 2px solid #22222a;
  border-radius: 10px;
  padding: 10px 30px;
  cursor: pointer;
}

.sign-up-button {
  background: #ff664f;
  border-radius: 10px;
  padding: 10px 30px;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .hero-titles {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    height: 80vh;
  }

  .hero-titles {
    width: 90%;
  }

  .hero-title {
    font-size: 32px;
    line-height: normal;
    margin-bottom: 0.5rem;
  }

  .hero-subtitle {
    font-size: 16px;
    line-height: 110%;
  }
}
