footer {
  background-color: #1e2029;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  margin-top: 50px;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.social-icons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.social-icons a {
  color: #fff;
  text-decoration: none;
}

.social-icons i {
  font-size: 24px;
}

p {
  font-size: 14px;
  margin: 0;
}
