.login_container {
  width: 100%;
  min-height: 100vh;
  background-color: #12141d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_form_container {
  width: 900px;
  height: 500px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1e2029;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_container h1 {
  font-size: 40px;
  margin-top: 0;
  color: white;
}

.input {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #272932;
  margin: 5px 0;
  font-size: 14px;
}

.error_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #272932;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.right h1 {
  margin-top: 0;
  color: white;
  font-size: 40px;
  align-self: center;
}

.secondary_btn {
  display: none;
}

.white_btn,
.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.green_btn {
  background-color: #ff664f;
  color: white;
  margin: 10px;
}

.green_btn:active {
  background-color: #22222a;
}

@media (max-width: 768px) {
  .login_form_container {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .right {
    display: none;
  }

  .login_form_container {
    width: 90%;
  }

  .input {
    width: 80%;
  }

  .error_msg {
    width: 80%;
  }

  .green_btn {
    margin-top: 2rem;
  }

  .secondary_btn {
    display: inline-block;
    margin: 0;
    background-color: #1e2029;
    color: #6d7d8b;
    cursor: pointer;
  }
}
