.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 63px;

  text-align: center;

  color: #ffffff;
}

.contact-us-container {
  display: flex;
  justify-content: space-between;
  background-color: #1e2029;
  flex-wrap: wrap;
  width: 80%;
  padding: 10px 50px 10px 10px;
  border-radius: 20px;
}

.contact-info {
  /* flex: 1; */
  border-radius: 8px;
  padding: 0 2rem 0 1.5rem;
  width: 30%;
  margin-right: 1.5rem;
  background: #272932;
}

.contact-info h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 60px;
  text-align: left;
  margin-bottom: 0;
  /* identical to box height, or 250% */

  color: #ffffff;
}

.contact-info h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140.2%;
  text-align: left;
  margin-bottom: 2rem;
  /* or 24px */

  color: rgba(255, 255, 255, 0.8);
}

.contact-info p {
  color: #ffffff;
}

.contact {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.contact img {
  margin-right: 10px;
}

.social-media {
  display: flex;
  justify-content: space-between;
  max-width: 10rem;
  margin-top: 3rem;
}

.social-media a {
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  font-size: 1.5rem;
}

.contact-form {
  flex: 2;
}

.contact-form h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 48%;
  color: #ffffff;
}

.input-group label {
  margin-bottom: 0.5rem;
}

.input-group input,
.input-group textarea {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #272932;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #ffffff;
  background: #272932;
}

.input-group textarea {
  height: 8rem;
}

.width {
  width: 100%;
}

.form-button {
  background-color: #12141d;
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 85%;
}

.form-button:hover {
  background-color: #ff664f;
}

.error_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 768px) {
  /* .contact-info {
    width: 80%;
  } */
}

@media (max-width: 600px) {
  .contact-us h2 {
    font-size: 30px;
  }

  .contact-us-container {
    width: 90%;
    padding-right: 10px;
    padding-bottom: 2rem;
  }
  .contact-info {
    width: 100%;
    margin: 0;
    padding-right: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-info h2 {
    text-align: center;
  }

  .contact-info h3 {
    text-align: center;
  }

  .contact {
    display: flex;
    flex-direction: column;
  }

  .contact * {
    margin-bottom: 1rem;
  }

  .input-row {
    display: flex;
    flex-direction: column;
  }

  .input-group {
    width: 100%;
    margin-bottom: 1rem;
  }

  .input-group label {
    text-align: center;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .form-button {
    align-self: center;
    margin-left: 0;
    width: 180px;
  }
}
