.navbar {
  width: 100%;
  height: 70px;
  background-color: #12141d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar h1 {
  color: white;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  margin-left: 20px;
}

.white_btn {
  border: none;
  outline: none;
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  width: 120px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-right: 20px;
}
