.features {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 63px;
  margin: 0;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.features p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 163.15%;
  margin-bottom: 20px;
  /* identical to box height, or 33px */

  text-align: center;

  /* Grey */

  color: #6d7d8b;
}

.feature-section {
  background-color: #1e2029;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  padding: 50px;
  border-radius: 20px;
}

.feature-section div {
  width: 30%;
  text-align: left;
}

.feature-section h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 31px;

  color: #ffffff;
}

.feature-section p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: left;

  /* Grey */

  color: #6d7d8b;
}

.feature-section img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .feature-section {
    display: flex;
    padding: 5%;
  }
}

@media (max-width: 600px) {
  .feature-section {
    display: flex;
    flex-direction: column;
  }

  .feature-section div {
    width: 90%;
    text-align: center;
    padding-left: 5%;
    margin-bottom: 1rem;
  }

  .feature-section h3 {
    line-height: 10px;
    /* margin-top: 40px; */
  }

  .feature-section div p {
    text-align: center;
    line-height: 110%;
  }

  .features h2 {
    font-size: 30px;
  }

  .features p {
    font-size: 16px;
  }
}
