.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works h2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 63px;
  /* margin-top: 100; */
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.how-it-works-container {
  background-color: #1e2029;
  width: 80%;
  padding: 50px;
  border-radius: 20px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.how-it-works-container * {
  margin-top: 20px;
}

.how-it-works-container h3 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 31px;

  color: #ffffff;
}

.how-it-works-container p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: left;

  /* Grey */

  color: #6d7d8b;
}

@media (max-width: 768px) {
  .how-it-works-container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .how-it-works {
    width: 100%;
  }

  .how-it-works-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 5%;
    text-align: center;
  }

  .how-it-works-container * {
    margin-top: 0.5rem;
  }

  .line {
    display: none;
  }

  .how-it-works-container .item:nth-child(1) {
    order: 2;
  }

  .how-it-works-container .item:nth-child(3) {
    order: 1;
  }

  .how-it-works-container .item:nth-child(4) {
    order: 3;
  }

  .how-it-works-container .item:nth-child(6) {
    order: 4;
  }

  .item.a {
    order: 6;
  }

  .item.b {
    order: 5;
  }

  .item img {
    margin-bottom: 1rem;
  }

  .how-it-works h2 {
    font-size: 30px;
  }

  .how-it-works p {
    font-size: 16px;
  }

  .how-it-works-container p {
    text-align: center;
    line-height: 110%;
  }

  .how-it-works-container h3 {
    line-height: 50%;
  }

  .how-it-works-container img {
    width: 90%;
  }
}
