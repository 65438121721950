body {
  background-color: #12141d;
  display: grid;
  /*grid-template-rows: 80px 1fr 80px;  header, main, footer */
  grid-template-columns: 100%;
}

header,
footer {
  /* grid-row: 1 / 2;  header takes up first row */
  grid-column: 1 / -1; /* header takes up all columns */
}

main {
  /*grid-row: 2 / 3;  main takes up second row */
  grid-column: 1 / -1; /* main takes up all columns */
  display: grid;
  /*grid-template-rows: 1fr 1fr 1fr 1fr 1fr; hero, features, how it works, pricing, contact us*/
  grid-template-columns: 100%;
}

#hero,
#features,
#how-it-works,
#pricing,
#contact-us {
  /*grid-row: 1 / 2;  each section takes up one row */
  grid-column: 1 / -1; /* each section takes up all columns */
}
